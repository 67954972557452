import { get, isEmpty } from "lodash"
import { RoleType } from "../constants/roles"
import personOrganizations from "../redux/actions/personOrganizations"
import {
  CallAnalyticsReportFour,
  CallAnalyticsReportOne,
  CallAnalyticsReportOneToExport,
  CallAnalyticsReportThreeToExport,
  CallAnalyticsReportTwo,
  ExportedCallAnalyticsReport,
} from "../types/CallAnalyticsType"
import { EditPersonOrganizations, ExportedPersonOrganization, ExportedPersonOrganizations, Header, Person, PersonOrganization } from "../types/PersonType"
import { dateFormatForReports } from "./DateHelper"
import { roundPrecision, secondsToMinutes } from "./FormatHelper"
import TextHelper from "./TextHelper"
import programs from "../redux/actions/programs"

type GetAnalyticsForExportReportTwo = (callAnalytics: CallAnalyticsReportTwo[], extraFields?: string[]) => ExportedCallAnalyticsReport
type GetAnalyticsForExportReportThree = (callAnalytics: CallAnalyticsReportThreeToExport[], extraFields?: string[]) => ExportedCallAnalyticsReport
type GetAnalyticsForExportReportFour = (callAnalytics: CallAnalyticsReportFour[]) => ExportedCallAnalyticsReport
type ParseMatchesToDisplay = (matches: any[], oppositeRole: RoleType) => any
type GetMatchesString = (matches: Person[]) => string
type GetValueString = (value: boolean) => string

const customExtraFields = [
  {
    label: "Gender",
    value: "gender",
    key: "personDetail.gender",
  },
  { label: "Date of Birth", value: "dateOfBirth", key: "personDetail.dateOfBirth" },
  { label: "Zip Code", value: "zipCode", key: "personDetail.zipCode" },
  { label: "Opted Out", value: "optedOut", key: "optedOut" },
  { label: "Preferred Language", value: "preferredLanguage", key: "personDetail.preferredLanguage" },
  { label: "Preferred Time for Call", value: "preferredTimeForCall", key: "personDetail.preferredTimeForCall" },
  { label: "Preferred Method of Communication", value: "preferredMethodOfCommunication", key: "personDetail.preferredMethodOfCommunication" },
  { label: "Caller Behavior", value: "phoneCallBehavior", key: "personDetail.phoneCallBehavior" },
  { label: "Hobbies", value: "hobbies", key: "personDetail.hobbies" },
]

export const getPersonOrganizationsToEdit = (personOrganization: PersonOrganization): EditPersonOrganizations | null => {
  if (!personOrganization) return null

  const { person, surveyOptOuts }: PersonOrganization = personOrganization
  const { personDetail } = person

  return {
    ...person,
    active: personOrganization.active,
    email: person.email ?? "",
    firstName: person.firstName ?? "",
    lastName: person.lastName ?? "",
    phone: person.phone ?? "",
    phoneAlternate: person.phoneAlternate ?? "",
    pin: personOrganization.pin ?? "",
    preferredName: person.preferredName ?? "",
    program: personOrganization.program.id ?? "",
    programId: personOrganization.program.id ?? "",
    organizationId: personOrganization.organizationId ?? "",
    roleId: personOrganization.roleId ?? "",
    personId: personOrganization.personId ?? "",
    userName: person.userName ?? "",
    personDetailId: person.personDetailId || null,
    externalPersonId: person.externalPersonId ?? "",
    organizationPersonStatusId: personOrganization.organizationPersonStatusId,
    personDetail: {
      zipCode: personDetail?.zipCode ?? "",
      dateOfBirth: personDetail?.dateOfBirth ?? "",
      gender: personDetail?.gender ?? "",
      preferredLanguage: personDetail?.preferredLanguage ?? "",
      hobbies: personDetail?.hobbies ?? "",
      phoneCallBehavior: personDetail?.phoneCallBehavior ?? "",
      preferredTimeForCall: personDetail?.preferredTimeForCall || ([] as string[]),
      preferredMethodOfCommunication: personDetail?.preferredMethodOfCommunication || ([] as string[]),
      phoneType: personDetail?.phoneType ?? "",
      phoneAlternateType: personDetail?.phoneAlternateType ?? "",
    },
    surveyOptOuts: surveyOptOuts || [],
    customFields: personOrganization.customFields || [],
  }
}

const getExtraFields = (rootNode: string, extraFields?: string[]) => {
  if (isEmpty(extraFields)) return null

  return (
    extraFields!.map((field) => {
      const found = customExtraFields.find((extraField) => extraField.value === field)
      const label = found?.label || `Custom Field (${field})`
      const key = found && found.value !== "optedOut" ? `${rootNode}${found.key ?? field}` : field
      return {
        label,
        key,
      }
    }) || []
  )
}

export const getPersonOrganizationsForExport = (personOrganizations: PersonOrganization[], extraFields?: string[]) => {
  const extraFieldResult = getExtraFields("person.", extraFields) || []

  const headers: Header[] = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Preferred Name", key: "preferredName" },
    { label: "Phone", key: "phone" },
    { label: "Mobile", key: "phoneAlternate" },
    { label: "Email", key: "email" },
    { label: "Matched", key: "matched" },
    { label: "Organization", key: "organization" },
    { label: "Program", key: "program" },
    { label: "Role", key: "role" },
    { label: "PIN", key: "pin" },
    { label: "Created at", key: "createdAt" },
    { label: "Updated at", key: "updatedAt" },
    { label: "Matches", key: "matches" },
    { label: "Active", key: "active" },
    { label: "Current Status", key: "status" },
    { label: "Assigned Queues", key: "callListQueueNames" },
    ...extraFieldResult,
  ]

  const fileData: ExportedPersonOrganization[] = personOrganizations.map((personOrganization: PersonOrganization) => ({
    firstName: personOrganization.person.firstName,
    lastName: personOrganization.person.lastName,
    preferredName: personOrganization.person.preferredName,
    phone: personOrganization.person.phone,
    phoneAlternate: personOrganization.person.phoneAlternate || "",
    email: personOrganization.person.email || "",
    matched: getValueString(!isEmpty(personOrganization.matches ?? [])),
    organization: personOrganization.organization?.name,
    program: personOrganization.program.name || "",
    role: personOrganization.role.name,
    pin: personOrganization.pin,
    createdAt: personOrganization.person.createdAt,
    updatedAt: personOrganization.person.updatedAt,
    matches: getMatchesString(personOrganization.matches || []),
    active: getValueString(personOrganization.active || false),
    status: personOrganization?.organizationPersonStatus?.name ?? "",
    callListQueueNames: personOrganization.callListQueueNames?.join(", ") || "",
    ...extraFieldResult.reduce((acc, extraField) => {
      const { key } = extraField
      const value = get(personOrganization, key, "-")
      return {
        ...acc,
        [key]: value,
      }
    }, {}),
  }))
  return {
    headers,
    data: fileData,
  }
}

export const getAnalyticsForExportReportOne = (callAnalytics: CallAnalyticsReportOne[], extraFields?: string[]) => {
  const headers: Header[] = [
    { label: "Callers with Calls Made", key: "callersCalls" },
    { label: "Callers with NO Calls Made", key: "callersNotCalls" },
    { label: "Recipients with Calls Received", key: "recipientCalls" },
    { label: "Recipients with NO Calls Received", key: "recipientNotCalls" },
    { label: "Inactive Callers with Calls Made", key: "inactiveCallerCalls" },
    { label: "Inactive Recipients with Calls Received", key: "inactiveRecipientCalls" },
    { label: "Total Calls", key: "totalCalls" },
    { label: "Total Call Time (Hours)", key: "totalDurationTime" },
    { label: "Total Call Time (Min)", key: "totalDuration" },
    { label: "Organization", key: "organization" },
  ]

  const fileData: CallAnalyticsReportOne[] = callAnalytics.map((call) => {
    return {
      callersCalls: call.callersCalls,
      callersNotCalls: call.callersNotCalls,
      recipientCalls: call.recipientCalls,
      recipientNotCalls: call.recipientNotCalls,
      inactiveCallerCalls: call.inactiveCallerCalls,
      inactiveRecipientCalls: call.inactiveRecipientCalls,
      totalCalls: call.totalCalls,
      totalDurationTime: call.totalDurationTime || "00:00:00",
      totalDuration: roundPrecision(parseFloat(call.totalDuration || "0"), 1).toString(),
      organization: call.organization,
    }
  })

  return {
    headers,
    data: fileData,
  }
}

export const getAnalyticsForExportReportTwo: GetAnalyticsForExportReportTwo = (callAnalytics, extraFields) => {
  const headers: Header[] = [
    { label: "From", key: "callerName" },
    { label: "To", key: "recipientName" },
    { label: "Total Calls", key: "callCount" },
    { label: "Total Call Time (Hours)", key: "totalDurationTime" },
    { label: "Total Call Time (Min)", key: "totalDuration" },
    { label: "Organization", key: "organizationName" },
    { label: "Caller Program", key: "callerProgram" },
    { label: "Recipient Program", key: "recipientProgram" },
    { label: "Caller Status", key: "callerStatus" },
    { label: "Recipient Status", key: "recipientStatus" },
    { label: "Currently Matched", key: "currentlyMatched" },
    { label: "Match Created", key: "matchCreated" },
    { label: "Match Deleted", key: "matchDeleted" },
  ]

  const fileData: CallAnalyticsReportTwo[] = callAnalytics.map((call) => {
    return {
      callerName: call["From"],
      recipientName: call["To"],
      callCount: call["Total Calls"],
      totalDurationTime: call["Total Call Time (Hours)"] || "00:00:00",
      totalDuration: roundPrecision(parseFloat(call["Total Call Time (Min)"] || "0"), 1).toString(),
      organizationName: call["Organization"] || "",
      callerProgram: call["Caller Program"] || "",
      recipientProgram: call["Recipient Program"] || "",
      callerStatus: call["Caller Status"] || "",
      recipientStatus: call["Recipient Status"] || "",
      currentlyMatched: call["Currently Matched"] || "",
      matchCreated: call["Match Created"] || "",
      matchDeleted: call["Match Deleted"] || "",
    }
  })

  return {
    headers,
    data: fileData,
  }
}

export const getAnalyticsForExportReportThree: GetAnalyticsForExportReportThree = (callAnalytics, extraFields) => {
  const extraFieldResult = getExtraFields("", extraFields) || []
  const headers: Header[] = [
    { label: "Name", key: "name" },
    { label: "Type", key: "type" },
    { label: "Total Calls", key: "callCount" },
    { label: "Total Call Time (Hours)", key: "totalDurationTime" },
    { label: "Total Call Time (Min)", key: "totalDuration" },
    { label: "Date Last Active", key: "mostRecentCall" },
    { label: "Current Matches", key: "matchNames" },
    { label: "Current Program", key: "programs" },
    { label: "Organization", key: "organizationName" },
    { label: "Active", key: "active" },
    ...extraFieldResult,
  ]

  const fileData: CallAnalyticsReportThreeToExport[] = callAnalytics.map((callAnalytic: CallAnalyticsReportThreeToExport) => {
    const date: string = dateFormatForReports(callAnalytic?.mostRecentCall)
    return {
      active: getValueString(!!callAnalytic?.active || false),
      name: callAnalytic?.name,
      type: callAnalytic?.type,
      callCount: callAnalytic?.callCount,
      totalDurationTime: callAnalytic?.totalDurationTime || "00:00:00",
      totalDuration: roundPrecision(parseFloat(callAnalytic?.totalDuration || "0"), 1).toString(),
      mostRecentCall: date,
      matchNames: callAnalytic?.matchNames || "",
      programs: callAnalytic?.programs || "",
      organizationName: callAnalytic?.organizationName || "",
      ...extraFieldResult.reduce((acc, extraField) => {
        const { key } = extraField
        const value = get(callAnalytic, key, "-")
        return {
          ...acc,
          [key]: value,
        }
      }, {}),
    }
  })

  return {
    headers,
    data: fileData,
  }
}

export const getAnalyticsForExportReportFour: GetAnalyticsForExportReportFour = (callAnalytics) => {
  const headers: Header[] = [
    { label: "Name", key: "name" },
    { label: "Total Calls", key: "callCount" },
    { label: "Total Call Time", key: "duration" },
    { label: "Total Call Time (Min)", key: "totalDuration" },
    { label: "List of Matches", key: "matchNames" },
    { label: "Program(s)", key: "programs" },
    { label: "Type", key: "type" },
    { label: "Date Last Active", key: "mostRecentCall" },
  ]

  const fileData: CallAnalyticsReportFour[] = callAnalytics.map((callAnalytic: CallAnalyticsReportFour) => {
    const date: string = dateFormatForReports(callAnalytic.mostRecentCall)

    const totalDuration: number = parseInt(callAnalytic.totalDuration.toString(), 10)
    const minutesTotalDuration: number = secondsToMinutes(totalDuration)
    const roundedTotalDuration: number = roundPrecision(minutesTotalDuration, 1)

    return {
      name: callAnalytic.name,
      callCount: callAnalytic.callCount,
      totalDuration: roundedTotalDuration,
      duration: TextHelper.getFormattedTime(totalDuration) || "",
      matchNames: callAnalytic.matchNames,
      programs: callAnalytic.programs,
      type: callAnalytic.type,
      mostRecentCall: date,
    }
  })

  return {
    headers,
    data: fileData,
  }
}

export const parseMatchesToDisplay: ParseMatchesToDisplay = (matches, oppositeRole) => {
  return matches.map((match) => {
    switch (oppositeRole) {
      case RoleType.CALLER:
        return match.callee
      default:
        return match.caller
    }
  })
}

export const getMatchesString: GetMatchesString = (matches) => {
  if (matches.length === 0) return ""

  let matchesString = ""

  matches.forEach(({ firstName, lastName }, index) => {
    const name = `${firstName} ${lastName}`
    matchesString = index === 0 ? name : `${matchesString}, ${name}`
  })

  return matchesString
}

export const getValueString: GetValueString = (value) => (value ? "TRUE" : "FALSE")
